import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageType } from "../components/context/page-type";
import { useColorContext } from "../components/context/color-context";

// Components
import { Gallery } from "../components/images/gallery";
import { SingleVideoEmbed } from "../components/videos/single-video-embed";

const Page = styled.div`
  margin: 40px 0 0 0;

  @media (max-width: 900px) {
    margin: 55px 0 0 0;
  }
`;

const ContentContainer = styled.div`
  & .about-the-author.text {
    & .text-container {
      & .inner-text-container {
        & .about-the-author-inner-container {
          max-width: 600px;
          background-color: ${props => props.highlightColor};

          padding: 20px;

          & p {
            font-size: 16px;
            line-height: 23px;

            &:first-of-type {
              margin-top: 0;
            }

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  & .full-width-text {
    & .text-container {
      grid-column: 1 / 7;

      & p {
        font-size: 40px;
        line-height: 52px;

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        @media (max-width: 768px) {
          font-size: 26px;
          line-height: 34px;
        }
      }
    }
  }

  & .single-image {
    & .single-image-container {
      grid-column: 2 / 6;

      @media (max-width: 768px) {
        grid-column: 1 / 7;
      }
    }
  }

  & .single-video {
    & .single-video-container {
      grid-column: 2 / 6;

      @media (max-width: 768px) {
        grid-column: 1 / 7;
      }
    }
  }

  & .text {
    & .text-container {
      grid-column: 2 / 6;

      @media (max-width: 768px) {
        grid-column: 1 / 7;
      }

      & .inner-text-container {
        max-width: 895px;
        margin: 0 auto;

        & .section-title {
          & h1 {
            font-size: 40px;
            line-height: 52px;

            @media (max-width: 768px) {
              font-size: 26px;
              line-height: 34px;
            }
          }
        }

        & .section-text {
          & p:first-of-type {
            margin-top: 0;
          }

          & p:last-of-type {
            margin-bottom: 0;
          }

          & ol {
            margin: 1em 0;

            & li {
              list-style: decimal;
              margin: 0 0 1em 20px;
              padding: 0 0 0 10px;

              &:last-of-type {
                margin: 0 0 0 20px;
              }
            }
          }
        }
      }
    }
  }

  & .gallery {
    margin: 0 0 70px 0;

    & > div {
      grid-column: 1 / 7;

      max-width: 900px;
      margin: 0 auto;
    }

    @media (max-width: 768px) {
      margin: 40px 0;
    }
  }

  & .related-projects {
    & .section-title {
      grid-column: 1 / 7;

      margin: 0 0 40px 0;

      @media (max-width: 768px) {
        margin: 0;

        & h3 {
          font-size: 18px;
          line-height: 23px;
        }
      }
    }

    & .related-projects-container {
      grid-column: 1 / 7;
      grid-row-gap: 35px;

      & article {
        grid-column: span 2;

        & .title {
          margin: 14px 0 0 0;
        }

        @media (max-width: 768px) {
          grid-column: span 6;

          & h1,
          & h2 {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }
    }
  }
`;

const Grid = styled.div`
  max-width: 895px;
  /* display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 40px; */

  margin: 70px auto;

  @media (max-width: 768px) {
    margin: 40px 0;
  }
`;

const Title = styled.div`
  max-width: 895px;
  margin: 0 auto;

  & h1,
  & h2 {
    font-size: 40px;
    line-height: 52px;

    @media (max-width: 768px) {
      font-size: 26px;
      line-height: 34px;
    }
  }

  & .subtitle {
    & p {
      margin: 0;
    }
  }
`;

const GlossaryArticle = ({ data }) => {
  const [pageType, setPageType] = useContext(PageType);
  const { randomColors } = useColorContext();

  useEffect(() => {
    setPageType(`glossary-article`);
  }, [data]);

  const content = data.prismicGlossaryArticle.data.body.map(
    (content, index) => {
      if (content.slice_type === "about_the_author") {
        return (
          <Grid
            key={`text_${index}_${content.id}`}
            className="about-the-author text"
          >
            <div className="text-container">
              <div className="inner-text-container">
                <div
                  className="about-the-author-inner-container"
                  dangerouslySetInnerHTML={{
                    __html: content.primary.text.html,
                  }}
                />
              </div>
            </div>
          </Grid>
        );
      }

      if (content.slice_type === "text") {
        return (
          <Grid key={`text_${index}_${content.id}`} className="text">
            <div className="text-container">
              <div className="inner-text-container">
                <div
                  className="section-title"
                  dangerouslySetInnerHTML={{
                    __html: content.primary.section_title.html,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: content.primary.section_text.html,
                  }}
                />
              </div>
            </div>
          </Grid>
        );
      }

      if (content.slice_type === "image") {
        return (
          <Grid key={`image_${index}_${content.id}`} className="single-image">
            <div className="single-image-container">
              {content.primary.image1.fluid !== null && (
                <img
                  // className={ImageOrientation(content.image)}
                  srcSet={content.primary.image1.fluid.srcSetWebp}
                  src={content.primary.image1.fluid.srcWebp}
                  alt={content.primary.image1.alt}
                  loading={`lazy`}
                />
              )}
            </div>
          </Grid>
        );
      }

      if (content.slice_type === "video") {
        return (
          <Grid key={`video_${index}_${content.id}`} className="single-video">
            <div className="single-video-container">
              <SingleVideoEmbed content={content} index={index} />
            </div>
          </Grid>
        );
      }
    }
  );

  return (
    <>
      <Helmet
        title={`${data.prismicGlossaryArticle.data.title.text} | Three Rivers | Bexley`}
        meta={[
          {
            name: "title",
            content: `${data.prismicGlossaryArticle.data.title.text} | Three Rivers | Bexley`,
          },
          {
            property: "og:title",
            content: `${data.prismicGlossaryArticle.data.title.text} | Three Rivers | Bexley`,
          },
          {
            property: "twitter:title",
            content: `${data.prismicGlossaryArticle.data.title.text} | Three Rivers | Bexley`,
          },
        ]}
      />
      <Page>
        <Title>
          <div
            className="title uppercase"
            dangerouslySetInnerHTML={{
              __html: data.prismicGlossaryArticle.data.title.html,
            }}
          />
          <div
            className="subtitle"
            dangerouslySetInnerHTML={{
              __html: data.prismicGlossaryArticle.data.author.html,
            }}
          />
        </Title>

        <ContentContainer highlightColor={randomColors[1]}>
          {content}
        </ContentContainer>
      </Page>
    </>
  );
};

export default withPreview(GlossaryArticle);

export const query = graphql`
  query GlossaryArticle($uid: String!) {
    prismicGlossaryArticle(uid: { eq: $uid }) {
      id
      _previewable
      data {
        title {
          html
          text
        }
        author {
          html
          text
        }
        body {
          ... on PrismicGlossaryArticleBodyAboutTheAuthor {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicGlossaryArticleBodyText {
            id
            slice_type
            primary {
              section_title {
                text
                html
              }
              section_text {
                html
              }
            }
          }
          ... on PrismicGlossaryArticleBodyImage {
            id
            slice_type
            primary {
              image1 {
                fluid {
                  srcWebp
                  srcSetWebp
                }
                alt
              }
            }
          }
          ... on PrismicGlossaryArticleBodyVideo {
            id
            slice_type
            primary {
              video {
                height
                html
                width
              }
            }
          }
        }
      }
    }
  }
`;
